import React from 'react';
import { Title } from './component/Title';
import background from './assets/background.jpg';
import modepack_people from './assets/modepack_people.jpg';
import modepack_products from './assets/modepack_products.jpg';
import newsletter_landing from './assets/newsletter_landing.jpg';
import { Timer } from './component/Timer';
import { Subscribe } from './component/Subscribe';


export class App extends React.Component {

  render() {
    
    return (
      <React.Fragment>
        <div style={{background: `url(${newsletter_landing})`, backgroundSize: "cover"}} className="container">
          <div className="main-content">
            <Title></Title>
            {/* <Timer></Timer> */}
            <Subscribe></Subscribe>
          </div>
        </div>
        <div className="overlay">
          <div className="footer">
            <div className="footer__content">© 2021 Modepack All Rights Reserved.</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

