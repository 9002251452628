import React from 'react';
import Countdown from 'react-countdown-now';


export class Timer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const renderer = ({ days, hours, minutes, seconds }) => {
            return (
                <div className="timer">
                    <div className="timer__box">
                        {days}
                        <div>Days</div>
                    </div>
                    <div className="timer__box">
                        {hours}
                        <div>Hours</div>
                    </div>
                    <div className="timer__box">
                        {minutes}
                        <div>Minutes</div>
                    </div>
                    <div className="timer__box">
                        {seconds}
                        <div>Seconds</div>
                    </div>
                </div>
            );
        }

        return (
            <Countdown
                date={new Date(2019, 6, 1, 0, 0, 0, 0)}
                renderer={renderer}
            />
        );
    }
}

