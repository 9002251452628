import React from 'react';


export class Title extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="title">
        <div className="title__main">Welcome to <span>Modepack</span>, where thinking out of the box is the only way Towards perfection!</div>
        <div className="title__sub">Don't miss out on any news, promotions, special offers and our welcome <span>10% discount</span> on your first webshop order.</div>
      </div>
    );
  }
}

