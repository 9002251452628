import React from 'react';

import Mailchimp from 'react-mailchimp-form';

export class Subscribe extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      requriedMessage: `This field is required`,
      email: "",
      postRoute: "https://us20.api.mailchimp.com/3.0/lists/cae696ff02/members/",
      getRoute: "https://us20.api.mailchimp.com/3.0/lists/9e67587f52/members/"
    }

    this.checkForSuccess();
  }

  checkForSuccess() {
    console.log("=== check");
    let el = document.getElementById('mailchimp-success');
    if (el) {
      window.location.href = "https://modepack.com/";
      return;
    }
    console.log("=== repeat");
    setTimeout(this.checkForSuccess.bind(this), 2500);
  }

  render() {
    return (

      <div className="subscribe">
        {/* <div className="subscribe__title">Subscribe to our newsletter</div> */}
        <Mailchimp
          action='https://modepack.us20.list-manage.com/subscribe/post?u=cd59b520df5fca9cb31d18b81&amp;id=cae696ff02'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email address',
              type: 'email',
              required: true
            }
          ]}
          messages={
            {
              sending: "Sending...",
              success: <div id="mailchimp-success">Thank you for subscribing! Go to <a href="https://modepack.com/" style={{color: "white"}}>Modepack</a></div>,
              error: "This email cannot be added to this list. Probably not valid address.",
              empty: "You must write an e-mail address.",
              duplicate: "You are already subscribed!",
              button: "SUBSCRIBE"
            }
          }
        />
      </div>
    );
  }
}



